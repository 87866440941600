import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'
import AlphrPage from '../ui/pages/AlphrPage'

const Alphr = props => (
  <>
    <HelmetComponent
      title={metaTags.alphrTitle}
      description={metaTags.alphr}
      page="de/alphr"
    />
    <AppWrapper {...props} lang="de">
      <AlphrPage />
    </AppWrapper>
  </>
)

export default Alphr
